const TodosTypes = {
  SET_TODOS: "SET_TODOS",
  ADD_TODO: "ADD_TODO",
  UPDATE_TODO: "UPDATE_TODO",
  REMOVE_TODO: "REMOVE_TODO",
  TOGGLE_TODO_HIGHLIGHT: "TOGGLE_TODO_HIGHLIGHT",
  TOGGLE_TODO_FOCUS: "TOGGLE_TODO_FOCUS",
};

export default TodosTypes;
