const LocalStateTypes = {
  TOGGLE_MENU: "TOGGLE_MENU",
  CLOSE_MENU: "CLOSE_MENU",
  OPEN_ADD_TODO_MODAL: "OPEN_ADD_TODO_MODAL",
  CLOSE_ADD_TODO_MODAL: "CLOSE_ADD_TODO_MODAL",
  OPEN_ADD_PROJECT_MODAL: "OPEN_ADD_PROJECT_MODAL",
  CLOSE_ADD_PROJECT_MODAL: "CLOSE_ADD_PROJECT_MODAL",
  OPEN_ADD_LABEL_MODAL: "OPEN_ADD_LABEL_MODAL",
  CLOSE_ADD_LABEL_MODAL: "CLOSE_ADD_LABEL_MODAL",
  OPEN_SEARCH_MODAL: "OPEN_SEARCH_MODAL",
  CLOSE_SEARCH_MODAL: "CLOSE_SEARCH_MODAL",
  SET_INITIAL_DATA_LOADED: "SET_INITIAL_DATA_LOADED",
  SET_INITIAL_TODOS_LOADED: "SET_INITIAL_TODOS_LOADED",
  SET_INITIAL_PROJECTS_LOADED: "SET_INITIAL_PROJECTS_LOADED",
  SET_INITIAL_LABELS_LOADED: "SET_INITIAL_LABELS_LOADED",
  SET_INITIAL_COLORS_LOADED: "SET_INITIAL_COLORS_LOADED",
  ENQUEUE_SNACKBAR: "ENQUEUE_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  REMOVE_SNACKBAR: "REMOVE_SNACKBAR",
  SET_LIVE_REGION_MESSAGE: "SET_LIVE_REGION_MESSAGE",
  CLEAR_LIVE_REGION_MESSAGE: "CLEAR_LIVE_REGION_MESSAGE",
  SET_COOKIE_CONSENT_ACCEPTED: "SET_COOKIE_CONSENT_ACCEPTED",
  SET_COOKIE_CONSENT_DENIED: "SET_COOKIE_CONSENT_DENIED",
  OPEN_COOKIE_CONSENT: "OPEN_COOKIE_CONSENT",
};

export default LocalStateTypes;
